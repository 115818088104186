// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: url('~designUtils/Logo/Entry/Logo_color_icon_entry.svg');
}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  background: url('~designUtils/Logo/Logo_white_icon.svg');

}
.app-progess-bar{
  height: 94px  !important;
  width: 100% !important;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 10px;
  margin-right: 10px;
  background-size: cover !important;
  position: relative;
  background: url('~designUtils/img/progres_bar_svg.svg');
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}
