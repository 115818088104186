.upload-button input[type=file] {
    font-size: 18px;
  display: none;
  width: 100%;
  border: none;
  text-transform: none;

  opacity: 0;

}
.upload-button input[type=file]:focus{
    outline: none;
}
.upload-button .btn-link {
    text-decoration: none;
}

.upload-button .btn-link:hover {
    text-decoration: none;
}
.upload-button .btn-link span{
   font-size: 10px;
}


ul.thumb {
    margin: 0 auto;
    padding: 0;
    width: 11%;
    float: left;
}

ul.thumb li{
    list-style: none;
    margin: 5px;
    width: 60px;
    height: 60px;
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0,0, .2);
    overflow: hidden;
}

ul.thumb li img{
    width: 100%;
}

.imgBox{
    float: left;
    margin: 5px;
    width: 87%;
    min-height: 579px;
    height: 579px;
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0,0, .2);
    overflow: hidden;
}

.imgBox img{
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-button{
    padding: 0px !important;
}

.deleteImage{
    position: absolute;
    height: 30px;
    border: 1px solid #9c9c9c;
    width: 30px;
    float: right;
    border-radius: 20px;
    margin: 0px;
    z-index: 999;
    right: 11px;
    top: 30px;
    background: #fff
}

.deleteImage {
    position: absolute;
    bottom: -3px;
    color: #9c9c9c;
}
.r-3 {
  right: 3px;
}
.r-4 {
  right: 4px;
}
.r-5 {
  right: 5px;
}
.t-0 {
  top: 0px;
}
.t-1 {
  top: 1px;
}
.pos-ab {
  position: absolute;
}
.red {
  color: #de0047;
}
.green {
  color: #5FB81D;
}
.sequence {
  top: 70px !important;
}
.comment {
  /* background-color: #ff3535; */
  top: 110px;
}
.comment-icon {
  color: #de0047;
}
.pdf-comments {
  top: 45px !important;
}
.comment-shadow {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 55, 85, 0.84);
  -moz-box-shadow:    0px 0px 15px 0px rgba(255, 55, 85, 0.84);
  box-shadow:         0px 0px 15px 0px rgba(255, 55, 85, 0.84);
}
@keyframes blink {
    0% { box-shadow: 0 0 15px #b71b02; }
    50% { box-shadow: 0 0 0; }
    100% { box-shadow: 0 0 15px #b71b02; }
}

@-webkit-keyframes blink {
    0% { box-shadow: 0 0 15px #b71b02; }
    50% { box-shadow: 0 0 0; }
    100% { box-shadow: 0 0 15px #b71b02; }
}

.blink {
    -webkit-animation: blink 0.5s linear infinite;
    animation: blink 0.5s linear infinite;
}
/* PDF View Styles */
.pdfUploadSection{
    position: relative;
    margin: 0 0 15px;
    border: 2px dotted lightgray;
    padding: 35px 20px;
    border-radius: 6px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: white;
}

.bSJghW {
        font-weight: bold;
        -webkit-letter-spacing: 2.2px;
        -moz-letter-spacing: 2.2px;
        -ms-letter-spacing: 2.2px;
        letter-spacing: 2.2px;
        margin-top: 0;
        text-align: center;
}
.pdfViwer input,
.pdfViwer button {
  font: inherit;
}
.pdfViwer header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: white;
}
.pdfViwer header h1 {
  font-size: inherit;
  margin: 0;
}
.pdfViwer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}
.pdfViwer__container__load {
  margin-top: 1em;
  color: white;
}
.pdfViwer__container__document {
  margin: 1em 0;
  position: relative;
}
.pdfViwer__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pdfViwer__container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  border: 1px solid lightgray;
  margin: 1em;
}
.pdfViwer__container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}
.pdfViwer__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}

.pdfViwer__container__document .deleteImage{
    right: 3px;
    top: 6px;
}

.weRJm {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 2px solid #98a0a5;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    padding: 1.1em 2.8em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 6px;
    color: #3498db;
    position: relative;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: color 250ms ease-in-out;
    transition: color 250ms ease-in-out;
    font-family: "Open Sans",sans-serif;
    width: 45%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.arrows{
  position: absolute;
  bottom: -23px;
  width: 87%;
  z-index: 99
}

.kfyceN {
  margin: 10px 0 15px !important;
}
.project-title {
  font-size: 18px;
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -.022em;
}
.features {
  font-size: 16px;
  line-height: 2;
  font-weight: 400;
  letter-spacing: -.022em;
}
.no-bullets {
  list-style: none;
}

ul.thumb-cus {
  margin: 0 auto;
  padding: 0;
  width: 12%;
  float: left;
}

ul.thumb-cus li{
  list-style: none;
  margin: 4px;
  width: 50px;
  height: 50px;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0,0, .2);
  overflow: hidden;
  position: relative;
}

ul.thumb-cus li img{
  width: 100%;
}

.input-position{
  width: 100%;
  position: relative;
}

.quill-prod .ql-editor {
  min-height: 18em;
}

.no-box-shadow {
  box-shadow: none
}

.no-box-shadow:focus {
  box-shadow: none
}

.day {
  font-size: 9px
}

.border-highlight{
  border-color: #e77600 !important;
}

.mb2rem{
  margin-bottom: 2rem;
}

.img-preview .img-container{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.img-preview .img-container .img-card {
  padding: 0.25rem;
  width: 20%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
}
.img-card > div:first-of-type {
  height: 100%;
  position: relative;
}

.img-card .img{
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    background-color: rgba(5,5,5,0.55);
}
.t-40 {
  top: 40px !important;
}
.t-50 {
  top: 50px !important;
}
.t-80 {
  top: 80px !important;
}
.t-120 {
  top: 120px !important;
}
.b-2 {
  bottom: 2px;
}
.m-l-1rem {
  margin-left: 1rem;
}

.shadow-none{
  box-shadow: none !important;
}

.react-confirm-alert-body h1{
  font-size: 1.5rem !important;
}
.input-position  img {
    padding: 3%;
}

.list-group-item.active .btn-link{
  color: #fff;
}
.vertical-center {
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}