.PowerbiEmbed {
    text-align: center;
}

.App-logo {
    height: 10vmin ;
    pointer-events: none;
}



.PowerBI-Embed-container {
    height: 110vh;
    width: 100%!important;
    padding-left: 0px; 
    padding-right: 0px; 
    /* overflow: scroll;  */
} 
.w-100
{
    width: 100%!important;
}

.modal-body label {
    width: 100%;
}
.salesPopup
{
    min-height: 80vh;
}
.txt-blue 
{
    color: #3d85c6;
}
.text-blue 
{
    color: #042946!important;
}
.positive-reviews
{
    color: #01AB1C!important;
}
.flip-img
{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
#catalogue-item 
{
    width: 600px;
}
.font-18
{
    font-size: 18px!important;
}
#catalogue-item .width-250
{
    width: 250px!important;
}
#catalogue-item .width-350
{
    width: 400px!important;
}
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    } 
}

.App-header {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}


.margin-top
{
    margin-top: 70px;
}
