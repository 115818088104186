#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}
.product-long {
  color: #3f86c4;
}
.site-layout-background {
  background: #fff;
}

.App {
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}
body {
  font-size: 14px;
  line-height: 1.42861;
  font-weight: 400;
  letter-spacing: -0.016em;
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid lightgray;
}

.launchButton {
  background: linear-gradient(to bottom, #44c767 5%, #5fb81d 100%);
  background-color: #44c767;
  border-radius: 28px;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 17px;
  padding: 16px 31px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627;
}
.launchButton:hover {
  background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
  background-color: #5cbf2a;
}
.launchButton:active {
  position: relative;
  top: 1px;
}

.m-t-10 {
  margin-top: 10px;
}


ul.tutorialCard {
  list-style-type: none;
}

ul.tutorialCard li {
  padding-left: 2rem;
  background-image: url("designUtils/Logo/Icon_small_centered.png");
  background-position: 0 0;
  background-size: 25px 25px;
  background-repeat: no-repeat;
}

.project-add .form-check-inline,
.project-add .form-check-input {
  margin-top: 4px;
}

/* Projects list card design */
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.text-dark-75 {
  color: #3f4254 !important;
}

.font-weight-bolder {
  font-weight: 600 !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.product-items .card {
  min-height: 200px !important;
  height: calc(50% - 25px);
}
.label.label-light-warning {
  color: #ffa800;
  background-color: #fff4de;
}
.label.label-lg.label-inline {
  width: auto;
}

.label.label-inline.label-lg {
  padding: 0.9rem 0.75rem;
}
.label.label-lg {
  height: 24px;
  width: 24px;
  font-size: 0.9rem;
}
.label.label-inline {
  width: auto;
}
.label.label-inline {
  width: auto;
  padding: 0.15rem 0.75rem;
  border-radius: 0.42rem;
}
.pb-4,
.py-4 {
  padding-bottom: 1rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1rem !important;
}
.label {
  padding: 0;
  margin: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 0.8rem;
  background-color: #ebedf3;
  color: #3f4254;
  font-weight: 400;
}
/* .product-items .col-md-7 {
  margin-bottom: 2.5rem!important;
  align-items: center!important;
  display: flex!important;
} */
/* Projects list card design end */

/* Product cards design start */
.card.card-custom.card-stretch.gutter-b {
  height: calc(100% - 25px);
}
.card.card-custom.card-stretch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.card.card-custom {
  -webkit-box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);
  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);
  border: 0;
}
.gutter-b {
  margin-bottom: 25px;
}

.card.card-custom > .card-header .card-title .card-label {
  margin: 0 0.75rem 0 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.card.card-custom > .card-header .card-title,
.card.card-custom > .card-header .card-title .card-label {
  font-weight: 500;
  font-size: 1.275rem;
  color: #181c32;
}
.text-dark {
  color: #181c32 !important;
}
.font-weight-bolder {
  font-weight: 600 !important;
}
.font-1 {
  font-size: 1.275rem;
  margin: 0.5rem;
}
.capitalize {
  text-transform: capitalize;
}
.w100 {
  width: 100% !important;
}
.bold {
  font-weight: bold;
}
.p-15 {
  padding: 15px;
}
.p-t-10 {
  padding-top: 10px;
}
.float-right {
  float: right;
}
.top50 {
  position: relative;
  top: 50%;
}
.center {
  text-align: center;
}
.grey {
  color: #6c757d;
}
.heading {
  font-size: 22px;
  line-height: 1.07143;
  font-weight: 600;
  letter-spacing: -0.005em;
}
.w96 {
  width: 96%;
}
.w110 {
  width: 110%;
}
.t-5 {
  top: 5px;
  position: relative;
}
@media (min-width: 576px) {
  .brand-info .jumbotron {
    padding: 1rem 1rem;
  }
}
.symbol.symbol-60px > img {
  width: 42px;
  height: 60px;
}
.align-left {
  text-align: left;
}
.note {
  font-size: 14px;
  font-style: italic;
  color: #d60505;
  /* #9c9c9c */
}
.p-p-10 {
  padding: 3%;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-5 {
  padding-right: 5px;
}
.f12 {
  font-size: 12px;
}
.f-23 {
  font-size: 23px;
}
.blue {
  color: #3d85c6;
}
.box-shadow {
  box-shadow: -4px 5px 16px -6px rgb(183 183 183 / 76%);
  -webkit-box-shadow: -4px 5px 16px -6px rgb(183 183 183 / 76%);
  -moz-box-shadow: -4px 5px 16px -6px rgb(183 183 183 / 76%);
}
.badge-approve {
  color: #ffffff !important;
  background-color: #2c312f;
}
.fw-bolder {
  font-weight: 500 !important;
}
.fs-8 {
  font-size: 0.7rem !important;
}
.badge {
  display: inline-block;
  padding: 0.5em 0.75em;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3rem;
}
.badge-light-info {
  color: #7239ea;
  background-color: #e7dff9;
}
.badge-light-warning {
  color: #ffc700;
  background-color: #fff8dd;
}
.badge-light-orange {
  color: #ce7200;
  background-color: #ffeedd;
}
.badge-light-success {
  color: #18ad5b;
  background-color: #cff9e3;
}
.badge-light-blue {
  color: #00a1ff;
  background-color: #eaeeff;
}
.badge-light-grey {
  color: #ffffff;
  background-color: #848484d9;
}
.badge-dark-black {
  color: #ffffff;
  background-color: #3c3c3c;
}
.fw-bolder {
  font-weight: 600 !important;
}
.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.pointer {
  cursor: pointer;
}
.tr-0 {
  top: 0px !important;
  right: 0px !important;
}
.r-0 {
  right: 0px !important;
}
.f07 {
  font-size: 0.7rem;
}
.b-d-r {
  border-right: 1px dashed #d6d6d6;
}
.b-d-b {
  border-bottom: 1px dashed #c3c2c2;
}
.b-r-0 {
  border-radius: 0px !important;
}
.f-16 {
  font-size: 16px !important;
}
.capitalize {
  text-transform: capitalize;
}
.green-badge-approve {
  color: #2bbd6e !important;
  background-color: #e8fff3;
}
.green-badge {
  display: inline-block;
  padding: 0.5em 0.85em;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.475rem;
}
.badge-green-icon,
.text-success {
  color: #50cd89 !important;
}
.no-text-dec {
  text-decoration: none !important;
}
.react-confirm-alert-overlay {
  background: rgb(60 59 59 / 39%);
}
.badge-light-danger {
  color: #f1416c;
  background-color: #fff5f8;
}
.badge-light-primary {
  color: #009ef7;
  background-color: #f1faff;
}
.lowercase {
  text-transform: lowercase !important;
}
.pos-rel {
  position: relative;
}
.z-10 {
  z-index: 10;
}
.not-allowed {
  cursor: not-allowed;
}
.pagination-wrapper {
  overflow-x: auto;
}
.pagination-wrapper .pagination {
  justify-content: center;
  margin: 24px;
}
.img-container-thumb {
  width: 100%;
  height: 115px;
}
.img-thumb-fit {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.font-small {
  font-size: 0.6rem;
}
.badge-border {
  border: 1px solid;
  border-radius: 5px;
}
optgroup {
  font-size: 0.8rem;
}
.fixed-section {
  max-height: 300px;
  position: relative;
  overflow: auto;
  padding-left: 45px;
  padding-bottom: 10px;
}
#selling_products_count:focus {
  border-color: #ffffff !important;
  box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 25%) !important;
}
.bullet-vertical {
  width: 4px;
  height: 8px;
}
.bullet {
  display: inline-block;
  background-color: #b5b5c3;
  border-radius: 6px;
  width: 8px;
  height: 4px;
  flex-shrink: 0;
}
.bg-success {
  --bg-color: 80, 205, 137;
}
.bg-success {
  background-color: #50cd89 !important;
}
.bg-primary {
  --bg-color: 0, 158, 247;
}
.bg-primary {
  background-color: #009ef7 !important;
}
.bg-grey {
  background-color: #bcbfbf !important;
}
.bullet-vertical {
  width: 6px !important;
  height: 8px;
}
.bullet {
  display: inline-block;
  background-color: #b5b5c3;
  border-radius: 6px;
  width: 8px;
  height: 4px;
  flex-shrink: 0;
}
.h-40px {
  height: 40px !important;
}
.mb-8 {
  margin-bottom: 2rem !important;
}
.align-items-center {
  align-items: center !important;
}
.d-flex {
  display: flex !important;
}
@media (min-width: 1200px) {
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2rem;
  }
}
@media (min-width: 1200px) {
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2rem;
  }
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.text-gray-800 {
  color: #929292 !important;
}
.text-hover-primary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.fw-bolder {
  font-weight: 600 !important;
}
.fs-6 {
  font-size: 1.075rem !important;
}
.align-items-center {
  align-items: center !important;
}
.customer-section .tab-blue .btn-link {
  color: #009ef7 !important;
}
.tab-blue {
  border: 1px dashed #009ef7;
  border-left: none;
  border-radius: 5px;
}
.tab-grey {
  border: 1px dashed #c5c5c5f7;
  border-left: none;
  border-radius: 5px;
}
.progress {
  border: 1px solid #d5d6d6;
  height: 1em;
}
.progress .progress-bar:last-child {
  background: linear-gradient(
    to right,
    #8000e0e0 0%,
    #2795d6 45%,
    #09e279 100%
  );
}
.text-purple {
  color: #8000e0e0;
}
.img-fixed {
  width: 100%;
  height: 160px;
}
.lh-p5 {
  line-height: 0.5rem;
}

.grid-divider {
  overflow-x: hidden;
  position: relative;
}

.grid-divider > [class*='col-']:nth-child(n + 2):after {
  content: '';
  background-color: red;
  position: absolute;
  top: 0;
  bottom: 0;

  @media (max-width: 767px) {
    width: 100%;
    height: 1px;
    left: 0;
    top: -4px;
  }

  @media (min-width: 768px) {
    width: 1px;
    height: auto;
    left: -1px;
  }
}
